<template>
    <div class="variants_metafield_template">
        <div v-if="loader">
          <img id="loader" src="../../assets/Circles-menu-3.gif" />
      </div>
      <div v-if="!loader" class="card metafield">
        <div v-if="metafieldData.id">
            <h3>Metafield Details</h3>
            <ul>
                <li><span class="title">ID: </span><span class="value">{{ metafieldData.id }}</span></li>
                <li><span class="title">Product ID: </span><span class="value">{{ metafieldData.product_id }}</span></li>
                <li><span class="title">Variant id: </span><span class="value">{{ metafieldData.owner_id }}</span></li>
                <li><span class="title">namespace: </span><span class="value">{{ metafieldData.namespace }}</span></li>
                <li><span class="title">key: </span><span class="value">{{ metafieldData.key }}</span></li>
                <li><span class="title">date: </span><span class="value">{{ metafieldData.value }}</span></li>
            </ul>
            <div class="bottom_bar">
                <button @click="enableUpdatePopup(metafieldData.id)" @class="UpdateMetafield">Update</button>
            </div>
        </div>
        <div v-else>
            <h3>No Record Found!</h3>
            <div class="createMetafield">
                <button @click="enableCreatePopup($route.params.id)" @class="CreateMetafield">create</button>
            </div>
        </div>
    </div>
          <div v-if="popup" class="metafield_popup popup">
              <form @submit="createMetafields" method="post" class="updateMetafield">
                            <div class="control is-expanded">
                                <input readonly type="text" class="input" v-model="variant_id"/>
                            </div>
                            <div class="control is-expanded">
                                <input type="text" class="input" v-model="date"/>
                            </div>
                             <button class="btn btn-primary">Submit!</button>
                       </form>
            </div> 
            <div v-if="updatePopup" class="metafield_Updatepopup popup">
              <form @submit="updateMetafieldSubmit" method="post" class="Updatepopup">
                            <div class="control is-expanded">
                                <input readonly type="text" class="input" v-model="metafield_id"/>
                            </div>
                            <div class="control is-expanded">
                                <input type="text" class="input" v-model="updateDate"/>
                            </div>
                             <button class="btn btn-primary">Submit!</button>
                       </form>
            </div>
    </div>
  </template>
  
  <script>
  import { mapActions,mapGetters } from "vuex";
  export default {
    name: 'metafields',
     components: {
     },
      data() {
        return {
          errors:[],
          loader:true,
          variant_id:null,
          metafield_id:null,
          popup:false,
          updatePopup:false,
          metafieldData:[]
        }
     },
      methods: {
           ...mapActions(["getMetafields","createMetafield","updateMetafield"]),
           ...mapGetters(["isAuthenticated"]), 
           enableCreatePopup:async function (id) {
              if(id){
                    this.updatePopup = false;
                    this.popup = true;
                    this.variant_id = id;
              }
           },
           enableUpdatePopup:async function (id) {
              if(id){
                this.popup = false;
                  this.metafield_id = id;
                  this.updatePopup = true;
              }
           },
           createMetafields:async function (e) {
              e.preventDefault();
              const createMetafield =  await this.createMetafield({"id":this.variant_id,"date":this.date});
              if(createMetafield){
                this.metafieldData =  await this.getMetafields(this.$route.params.id);
                this.popup = false;
              }
           },
           updateMetafieldSubmit:async function (e) {
              e.preventDefault();
              const updateMetafield =  await this.updateMetafield({"id":this.metafield_id,"date":this.updateDate});
              if(updateMetafield){
                this.metafieldData =  await this.getMetafields(this.$route.params.id);
                this.updatePopup = false;
              }
           }
      },
      mounted:async function () {
        if(this.isLoggedIn && this.$route.params.id){
            this.metafieldData = await this.getMetafields(this.$route.params.id);
            this.loader = false;
        }
      },
      computed: {
        isLoggedIn : function(){
            return this.$store.getters.isAuthenticated
        }
      },
  
  
  }
  </script>
  